import { deleteDoc, doc } from "firebase/firestore";
import { db } from ".";
import algoliasearch from "algoliasearch";

export const deleteData = async ({ type, id }) => {
  const client = algoliasearch("5WR8VNEFVM", "2ed4c9e6cbba7481c225d4a791f3be8f");
  const index = client.initIndex("Blogs");
  index.deleteObject(id);
  await deleteDoc(doc(db, type, id));
};
