import { onAuthStateChanged } from "@firebase/auth";
import { auth } from "../../Firebase";
import { Button, TextField } from "../../Components";
import { UilEstate } from "@iconscout/react-unicons";
import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { addData } from "../../Firebase";
import { useParams, useHistory } from "react-router";
import { WithContext as ReactTags } from "react-tag-input";
import { Link } from "react-router-dom";

export const AdminAdd = () => {
  const adminUserName = process.env.REACT_APP_USERNAME;
  const history = useHistory();

  const [isAdmin, setAdmin] = useState();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      if (user.email === adminUserName) {
        setAdmin(true);
      }
    } else {
      setAdmin(false);
      history.push("/adminlogin");
    }
  });

  const { type } = useParams("type");

  const [content, setContent] = useState("");
  const [caption, setCaption] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [keyWords, setKeyWords] = useState([]);
  const [imgSrc, setImgSrc] = useState("");

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  function onContentChange(e) {
    setContent(e.target.getContent());
  }

  function onTitleChange(e) {
    setTitle(e.target.value);
  }

  function onCaptionChange(e) {
    setCaption(e.target.value);
  }
  async function onSubmit(e) {
    e.preventDefault();
    if (content !== "") {
      addData({
        type: type,
        data: content,
        title: title,
        caption: caption,
        keywords: keyWords,
        category: category,
        imgSrc: imgSrc,
      });

      history.push("/admin");
    }
  }

  const handleDelete = (i) => {
    setKeyWords(keyWords.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setKeyWords([...keyWords, tag]);
  };

  return (
    <div className="adminAdd">
      {isAdmin ? (
        <>
          {" "}
          <Link to="/admin">
            <Button color="second" border="rounded" shadow="0" className="admin__backTo">
              <UilEstate />
            </Button>
          </Link>
          <form onSubmit={(e) => onSubmit(e)} className="adminForm">
            <h1> ADD {type}</h1>
            <br />
            {/* Tags */}
            <ReactTags
              tags={keyWords}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              inputFieldPosition="bottom"
              classNames={{
                tags: "admin__formTags",
                tagInput: "admin__fromTagInput",
                tagInputField: "textfield",
                selected: "admin__fromTagSelected",
                tag: "admin__formTag",
                remove: "admin__fromTagRemove",
              }}
            />
            <br />

            {/* Category */}
            <TextField
              type="text"
              placeholder="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
            <br />
            <br />

            {/* Image Src */}
            <TextField
              type="text"
              placeholder="Image Url"
              value={imgSrc}
              onChange={(e) => setImgSrc(e.target.value)}
            />
            <textarea
              rows={2}
              className="textfield adminForm__title"
              placeholder="Title"
              type="text"
              value={title}
              onChange={(e) => onTitleChange(e)}
            />
            <textarea
              rows={4}
              className="textfield adminForm__caption"
              placeholder="Caption"
              type="text"
              value={caption}
              onChange={(e) => onCaptionChange(e)}
            />
            <div className="post__content">
              <Editor
                apiKey="99cx0x0jz5e0q1kn9tfv2fy2m82emwdvwujwmk3c32y5ft66"
                initialValue="Your Blog Content Goes here"
                init={{
                  selector: "div.tinymce",
                  font_formats: "Rubik=Rubik",
                  content_style:
                    "@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); body { font-family: 'Rubik', sans-serif; font-size:21px;color:#333}",
                  height: "100vh",
                  menubar: false,
                  toolbar_sticky: true,
                  autosave_interval: "30s",
                  plugins: [
                    "quickbars",
                    "advlist autolink lists link image ",
                    "charmap print preview anchor help",
                    "searchreplace visualblocks code",
                    "insertdatetime media table paste wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic underline strikethrough | \
            forecolor backcolor | fontselect | fontsizeselect | \
            alignleft aligncenter alignright |image code | \
            bullist numlist outdent indent | fullscreen | help",
                  quickbars_selection_toolbar:
                    "forecolor backcolor | bold italic | formatselect | quicklink blockquote",
                }}
                onChange={(e) => onContentChange(e)}
              />
            </div>

            <Button type="submit" className="adminForm__submit">
              Submit
            </Button>
          </form>
        </>
      ) : (
        <div className="loginFirst">
          <h1>Login First</h1>
        </div>
      )}
    </div>
  );
};
