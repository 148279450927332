import React from "react";
import {
  UilFacebookF,
  UilTwitter,
  // UilLinkedin,
  UilLink,
  // UilBookmark,
} from "@iconscout/react-unicons";

import { Avatar, Button } from "../../Components";
import "./post.css";
import { useParams } from "react-router";
import { getPost } from "../../Firebase";
import ReactHtmlParser from "react-html-parser";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const Post = () => {
  const { type, id } = useParams("type", "id");
  const post = getPost({ id: id, type: type });
  const websitelink = `milanwagle.com.np/${type}/${post ? post.id : "null "}`;

  return (
    <div className="post">
      {post[0] !== "###" ? (
        <>
          <Button color="second" border="rounded" shadow="0">
            {post.category}
          </Button>
          <h1 className="post__title">{post.title}</h1>
          <div className="post__caption">{post.caption}</div>
          <br /> <br />
          <div className="post__action">
            <div className="post__author">
              <Avatar />
              <h5>Milan Wagle ,</h5>
              <div className="post__time">{post.time.toDate().toDateString()}</div>
            </div>
            <div className="post__share">
              <UilTwitter
                onClick={() =>
                  window.open(`https://twitter.com/intent/tweet?text=${post.title} ${websitelink}`)
                }
              />

              <UilFacebookF
                onClick={() => window.open(`http://www.facebook.com/share.php?u=${websitelink}`)}
              />

              <CopyToClipboard text={websitelink}>
                <UilLink />
              </CopyToClipboard>
            </div>
          </div>
          <div className="post__content">{ReactHtmlParser(post.content)}</div>
        </>
      ) : (
        "LAODING ####"
      )}
    </div>
  );
};
