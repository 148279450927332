import React from "react";
import algoliasearch from "algoliasearch";
import { useEffect, useState } from "react";
import "./search.css";
import { Card } from "../../Components";

export const Search = () => {
  const [userInput, setUserInput] = useState("");
  const [query, setQuery] = useState([]);

  useEffect(() => {
    const client = algoliasearch("5WR8VNEFVM", "c23d85791513c76763ae29204400048b");
    const index = client.initIndex("Blogs");
    index.search(userInput).then(({ hits }) => {
      setQuery(hits);
    });
  }, [userInput]);

  return (
    <div className="search">
      <div className="search__container">
        <input
          className="search__input"
          placeholder="Search"
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
        />
      </div>
      <div className="search__hitContainer">
        {query.map((hit) => {
          const time = new Date(hit.time).toDateString();
          return (
            <Card
              link={`/${hit.type}/${hit.objectID}`}
              key={hit.objectID}
              time={time}
              title={hit.title}
              caption={hit.caption}
              category={hit.category}
              image={hit.imgSrc}
            />
          );
        })}
      </div>
    </div>
  );
};
