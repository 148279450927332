import React from "react";
import { getRecentdata } from "../../Firebase";
import { Button, Card } from "../../Components";

export const RecentPost = ({ type }) => {
  const recentPost = getRecentdata({ type: type, limits: 2 });
  return (
    <div className="recentPost">
      <Button color="second" border="rounded" shadow="0">
        Recent Post
      </Button>
      <div className="recentPost__container">
        {recentPost ? (
          recentPost.map((post) => {
            return (
              <Card
                key={post.id}
                link={`/${type}/${post.id}`}
                time={post.time.toDate().toDateString()}
                title={post.title}
                caption={post.caption}
                image={post.imgSrc}
              />
            );
          })
        ) : (
          <h1>LOADING ####</h1>
        )}
      </div>
    </div>
  );
};
