import algoliasearch from "algoliasearch";

import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from ".";

export const addData = async ({ type, title, data, caption, keywords, category, imgSrc }) => {
  const client = algoliasearch("5WR8VNEFVM", "2ed4c9e6cbba7481c225d4a791f3be8f");
  const time = serverTimestamp();
  const index = client.initIndex("Blogs");
  const colref = collection(db, type);
  await addDoc(colref, {
    time: time,
    title: title,
    content: data,
    caption: caption,
    keywords: keywords,
    category: category,
    imgSrc: imgSrc,
  }).then(async (e) => {
    await index
      .saveObject({
        type: type,
        time: new Date(),
        title: title,
        objectID: e.id,
        caption: caption,
        imgSrc: imgSrc,
        keywords: keywords,
        category: category,
      })
      .finally(() => {
        window.location.reload(true);
      });
  });
};
