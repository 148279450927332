import React from "react";
import { Switch, Route } from "react-router-dom";
import { Header, Footer, ComingSoon, NotFound } from "./Layouts";
import { Home, Post, Search } from "./Screens";
import { Admin, AdminAdd, AdminLogin, AdminEdit } from "./Admin/Screen";

export const Navigation = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/:type/:id" component={Post} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/Article" component={ComingSoon} />
        <Route exact path="/Gazal" component={ComingSoon} />
        <Route exact path="/admin">
          <Admin />
        </Route>
        <Route exact path="/adminlogin">
          <AdminLogin />
        </Route>
        <Route exact path="/admin/post/:type">
          <AdminAdd />
        </Route>
        <Route exact path="/admin/edit/:type/:id">
          <AdminEdit />
        </Route>
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
    </>
  );
};
