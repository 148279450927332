import React from "react";
import { Button } from "../../Components";
import "./comingsoon.css";
import comingsoon from "./comingsoon.png";
import { Link } from "react-router-dom";

export const ComingSoon = () => {
  return (
    <div className="commingsoon">
      <img src={comingsoon} alt="Comming Soon Img" />
      <h1>This Page is currently not available. </h1>

      <h4>For now compromize on our search page</h4>
      <br />
      <br />
      <Link to="/search">
        <Button color="second" border="rounded">
          Search
        </Button>
      </Link>
    </div>
  );
};
