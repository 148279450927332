import React from "react";
import {
  UilFacebookF,
  UilYoutube,
  UilInstagram,
  UilLinkedin,
  UilAngleDoubleRight,
  UilTwitter,
} from "@iconscout/react-unicons";
import { Button } from "../../Components";
import aboutPic from "./about-img.png";

export const About = () => {
  return (
    <div className="home__about">
      <img className="home__aboutImg" src={aboutPic} alt="about-svg" />
      <div className="home__aboutContent">
        <Button border="rounded" shadow="0" color="second">
          About Me
        </Button>
        <h1>A Little About Me</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc habitasse id velit dictumst
          sit nec dignissim dictum. Velit scelerisque sapien tristique mattis eu non lectus. Lacus
          et pharetra enim tincidunt lorem. Tellus odio ac magna augue consequat sit tincidunt
          ornare curabitur
        </p>
        <div className="home__aboutSocialMedia">
          <UilFacebookF />
          <UilYoutube />
          <UilInstagram />
          <UilLinkedin />
          <UilTwitter />
        </div>
        <div className="home__aboutMore">
          <Button color="second" border="rounded">
            <UilAngleDoubleRight />
          </Button>
        </div>
      </div>
    </div>
  );
};
