import React from "react";

import "./button.css";

export const Button = ({
  color = "first",
  border = "sm",
  children,
  shadow = "sm",
  className,
  ...rest
}) => {
  return (
    <button
      className={`button bg-${color} border-${border} shadow-${shadow} ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};
