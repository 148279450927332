import React from "react";
import { onAuthStateChanged } from "@firebase/auth";
import { useState, useEffect } from "react";
import { auth } from "../../Firebase";
import "../admin.css";
import algoliasearch from "algoliasearch";
import { Link } from "react-router-dom";
import { deleteData } from "../../Firebase";
import { useHistory } from "react-router-dom";
import { Button, Card } from "../../Components";

export const Admin = () => {
  const [isAdmin, setAdmin] = useState();
  const history = useHistory();
  const adminUserName = process.env.REACT_APP_USERNAME;

  const [userInput, setUserInput] = useState("");
  const [query, setQuery] = useState([]);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      if (user.email === adminUserName.toString()) {
        setAdmin(true);
      }
    } else {
      history.push("/adminlogin");
    }
  });

  useEffect(() => {
    const client = algoliasearch("5WR8VNEFVM", "c23d85791513c76763ae29204400048b");
    const index = client.initIndex("Blogs");
    index.search(userInput).then(({ hits }) => {
      setQuery(hits);
    });
  }, [userInput]);

  function handleDelete(type, objectID) {
    if (
      prompt(
        "'Y' to delete and 'N' to cancel, You cannot restore it in any from after deleting it once"
      ) === "Y"
    ) {
      deleteData({ type: type, id: objectID }).finally(() => window.location.reload(true));
    }
  }

  return (
    <>
      {isAdmin ? (
        <div className="admin">
          <div className="admin__logout">
            <Button
              border="rounded"
              shadow="0"
              onClick={() => {
                auth.signOut();
                history.push("/adminlogin");
              }}
            >
              Log Out
            </Button>
          </div>
          <div className="search">
            <div className="search__container">
              <input
                className="search__input"
                placeholder="Search"
                type="text"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
              />
              <div className="admin__addBtns">
                <Link to="/admin/post/Article">
                  <Button>Add Article</Button>
                </Link>
                <Link to="/admin/post/Gazal">
                  <Button>Add Gazal</Button>
                </Link>
              </div>
            </div>
            <div className="search__hitContainer">
              {query.map((hit) => {
                const time = new Date(hit.time).toDateString();
                return (
                  <div className="admin__card">
                    <Card image={hit.imgSrc} title={hit.title} time={time} key={hit.objectID} />
                    <div className="admin__cardActions">
                      <Button
                        shadow="0"
                        onClick={() => history.push(`/admin/edit/${hit.type}/${hit.objectID}`)}
                      >
                        Edit
                      </Button>
                      <Button
                        color="red"
                        shadow="0"
                        onClick={() => handleDelete(hit.type, hit.objectID)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="loginFirst">
          <h1>Login First</h1>
        </div>
      )}
    </>
  );
};
