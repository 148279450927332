import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "..";
import "./card.css";

export const Card = ({ image, title, link, time }) => {
  return (
    <div className="card">
      <Link to={link}>
        <img src={image} className="card__image" alt="card" />
      </Link>

      <div>
        <div className="card__author">
          <Avatar />
          <p>Milan Wagle, {time}</p>
        </div>
        <Link to={link}>
          <div className="card__content">
            <h4>{title}</h4>
          </div>
        </Link>
      </div>
    </div>
  );
};
