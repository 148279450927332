import React from "react";
import { onAuthStateChanged } from "@firebase/auth";
import { useState } from "react";
import { auth } from "../../Firebase";
import { useHistory } from "react-router-dom";
import { TextField, Button } from "../../Components";
import adminLogin from "../adminLogin";

export const AdminLogin = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const adminUserName = process.env.REACT_APP_USERNAME;
  const adminPassword = process.env.REACT_APP_PASSWORD;
  onAuthStateChanged(auth, (user) => {
    if (user) {
      if (user.email === adminUserName) {
        history.push("/admin");
      }
    }
  });

  function handleLogin() {
    if (userName === adminUserName) {
      if (password === adminPassword) {
        adminLogin({ userName: userName, password: password });
        history.push("/admin");
      }
    }
  }

  return (
    <div className="adminLogin">
      <h1>Login To Admin Page</h1>
      <form onSubmit={() => handleLogin()} className="adminLogin__form">
        <TextField
          placeholder="Enter User Name"
          type="text"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <br />
        <TextField
          placeholder="Enter Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="adminLogin__action">
          <Button type="submit">Login</Button>
        </div>
      </form>
    </div>
  );
};
