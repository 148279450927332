import { db } from ".";
import { onSnapshot, collection, query, orderBy, limit } from "firebase/firestore";
import { useEffect, useState } from "react";

const useToGetRecentdata = ({ type, limits }) => {
  const [recentData, setRecentData] = useState([]);
  const refWithoutLimit = query(collection(db, type), orderBy("time", "asc"));
  const refWithLimit = query(collection(db, type), orderBy("time", "asc"), limit(limits));

 
  useEffect(() => {
    onSnapshot(limits === 0 ? refWithoutLimit : refWithLimit, (snapShot) => {
      setRecentData(snapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });
    // react-hooks/exhaustive-deps
  }, [limits]);

  return recentData;
};
export { useToGetRecentdata as getRecentdata };
