import { useEffect, useState } from "react";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from ".";

const useToGetPost = ({ type, id }) => {
  const [post, setPost] = useState(["###"]);

  useEffect(() => {
    onSnapshot(doc(db, type, id), (doc) =>
      setPost(() => ({
        id: doc.id,
        ...doc.data(),
      }))
    );
  }, [type, id]);

  return post;
};
export { useToGetPost as getPost };
