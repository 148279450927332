import { onAuthStateChanged } from "@firebase/auth";
import { auth } from "../../Firebase";
import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { getPost, editData } from "../../Firebase";
import { useParams } from "react-router";
import { WithContext as ReactTags } from "react-tag-input";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, TextField } from "../../Components";
import { UilEstate } from "@iconscout/react-unicons";

export const AdminEdit = () => {
  const adminUserName = process.env.REACT_APP_USERNAME;
  const history = useHistory();

  const [isAdmin, setAdmin] = useState();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      if (user.email === adminUserName) {
        setAdmin(true);
      }
    } else {
      setAdmin(false);
      history.push("/adminlogin");
    }
  });

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const { type, id } = useParams("type", "id");
  const prevPost = getPost({ type: type, id: id });
  const [content, setContent] = useState("");
  const [caption, setCaption] = useState();
  const [category, setCategory] = useState("");
  const [keyWords, setKeyWords] = useState([]);
  const [title, setTitle] = useState("");
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    if (prevPost[0] !== "###") {
      setCaption(prevPost.caption);
      setContent(prevPost.content);
      setTitle(prevPost.title);
      setKeyWords(prevPost.keywords);
      setImgSrc(prevPost.imgSrc);
      setCategory(prevPost.category);
    }
  }, [prevPost]);

  function onContentChange(e) {
    setContent(e.target.getContent());
  }

  function onTitleChange(e) {
    setTitle(e.target.value);
  }

  function onCaptionChange(e) {
    setCaption(e.target.value);
  }

  const handleDelete = (i) => {
    setKeyWords(keyWords.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setKeyWords([...keyWords, tag]);
  };

  function onSubmit(e) {
    e.preventDefault();
    editData({
      type: type,
      id: id,
      data: content,
      title: title,
      time: prevPost.time,
      caption: caption,
      category: category,
      keyWords: keyWords,
      imgSrc: imgSrc,
    });

    history.push("/admin");
  }

  return (
    <div className="adminedit">
      <Link to="/admin">
        <Button color="second" border="rounded" shadow="0" className="admin__backTo">
          <UilEstate />
        </Button>
      </Link>
      {isAdmin ? (
        <form onSubmit={(e) => onSubmit(e)} className="adminForm">
          <h1> Edit {type}</h1>
          <br />
          <ReactTags
            tags={keyWords}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            inputFieldPosition="bottom"
            classNames={{
              tags: "admin__formTags",
              tagInput: "admin__fromTagInput",
              tagInputField: "textfield",
              selected: "admin__fromTagSelected",
              tag: "admin__formTag",
              remove: "admin__fromTagRemove",
            }}
          />
          <br />
          <TextField
            type="text"
            placeholder="Category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
          <br />
          <br />

          {/* Image Src */}
          <TextField
            type="text"
            placeholder="Image Url"
            value={imgSrc}
            onChange={(e) => setImgSrc(e.target.value)}
          />
          <textarea
            rows={2}
            className="textfield adminForm__title"
            placeholder="Title"
            type="text"
            value={title}
            onChange={(e) => onTitleChange(e)}
          />
          <textarea
            rows={4}
            type="text"
            className="textfield adminForm__caption"
            placeholder="Caption"
            value={caption}
            onChange={(e) => onCaptionChange(e)}
          />
          <div className="post__content">
            <Editor
              apiKey="99cx0x0jz5e0q1kn9tfv2fy2m82emwdvwujwmk3c32y5ft66"
              initialValue={prevPost[0] !== "###" ? prevPost.content : prevPost[0]}
              init={{
                selector: "div.tinymce",
                font_formats: "Rubik=Rubik",
                content_style:
                  "@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); body { font-family: 'Rubik', sans-serif; font-size:21px;color:#333}",
                height: "100vh",
                menubar: false,
                toolbar_sticky: true,
                autosave_interval: "30s",
                plugins: [
                  "quickbars",
                  "advlist autolink lists link image ",
                  "charmap print preview anchor help",
                  "searchreplace visualblocks code",
                  "insertdatetime media table paste wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic underline strikethrough | \
            forecolor backcolor | fontselect | fontsizeselect | \
            alignleft aligncenter alignright |image code | \
            bullist numlist outdent indent | fullscreen | help",
                quickbars_selection_toolbar:
                  "forecolor backcolor | bold italic | formatselect | quicklink blockquote",
              }}
              onChange={(e) => onContentChange(e)}
            />
          </div>
          <Button type="submit" className="adminForm__submit">
            Edit
          </Button>
        </form>
      ) : (
        <div className="loginFirst">
          <h1>Login First</h1>
        </div>
      )}
    </div>
  );
};
