import notfound from "./notfound.png";
import "./notfound.css";
import React from "react";
import { Button } from "../../Components";

export const NotFound = () => {
  return (
    <div className="notfound">
      <img src={notfound} alt="Not Found Img" />
      <h1>ERROR : 404 </h1>
      <h4>Sorry, Page you are looking for, not found</h4>
      <br />
      <br />
      <Button color="second" border="rounded">
        Home
      </Button>
    </div>
  );
};
