import React from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { navList, socialLink } from ".";
import { UilBars } from "@iconscout/react-unicons";

export const Header = () => {
  const [shadowVisible, setShadowVisible] = useState();
  const [navState, setNavState] = useState(window.innerWidth >= 932 ? "navVisible" : "navHidden");

  // Adding Shadow To Header While Scrolling Website
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      window.scrollY > 120 ? setShadowVisible("header__shadow") : setShadowVisible("");
    });
  }, []);

  // Toggling the state of navigation after click event on menubar
  function onMenuBarClick() {
    setNavState((prevState) => (prevState === "navHidden" ? "navVisible" : "navHidden"));
  }

  // Setting NavBar vissible when screen is greate then 932px
  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth >= 932 ? setNavState("navVisible") : setNavState("navHidden");
    });
  }, []);

  return (
    <div className={`header screen ${shadowVisible}`}>
      <Link to="/" className="header__title">
        <h3>GURU</h3>
      </Link>

      <ul className={`header__links ${navState}`}>
        {navList.map((list, index) => {
          return (
            <Link to={list.link} onClick={() => window.innerWidth < 932 ?  setNavState("navHidden") : null} key={index + 1}>
              {list.icon}
              <li>{list.label}</li>
            </Link>
          );
        })}
      </ul>
      <div className="header__menubar" onClick={() => onMenuBarClick()}>
        <UilBars />
      </div>
      <div className={`header__socialMedia ${navState}`}>
        {socialLink.map((socialmedia, index) => {
          return (
            <a rel="noopener noreferrer" target="_blank" href={socialmedia.link} key={index + 1}>
              {socialmedia.icon}
            </a>
          );
        })}
      </div>
    </div>
  );
};
