import React from "react";
import "./home.css";
import { RecentPost, Landing, About } from ".";

export const Home = () => {
  return (
    <div className="home">
      <Landing />
      <About />

      <RecentPost type={"Article"} />
      {/* <RecentPost type={"Gazal"} /> */}
    </div>
  );
};
