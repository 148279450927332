import React from "react";
import Home from "@iconscout/react-unicons/icons/uil-estate";
import Gazal from "@iconscout/react-unicons/icons/uil-diary";
import Facebook from "@iconscout/react-unicons/icons/uil-facebook-f";
import Youtube from "@iconscout/react-unicons/icons/uil-youtube";
import Instagram from "@iconscout/react-unicons/icons/uil-instagram";
import Linkedin from "@iconscout/react-unicons/icons/uil-linkedin";
import Twitter from "@iconscout/react-unicons/icons/uil-twitter";
import { UilNotebooks } from "@iconscout/react-unicons";

export const navList = [
  { label: "Home", link: "/", icon: <Home /> },
  { label: "Article", link: "/Article", icon: <UilNotebooks /> },
  { label: "Gazal", link: "/Gazal", icon: <Gazal /> },
];

export const socialLink = [
  {
    label: "Facebook",
    icon: <Facebook />,
    link: "https://www.facebook.com/profile.php?id=100075055895484",
  },
  {
    label: "Youtube",
    icon: <Youtube />,
    link: "https://www.facebook.com/profile.php?id=100075055895484",
  },
  {
    label: "Instagram",
    icon: <Instagram />,
    link: "https://www.facebook.com/profile.php?id=100075055895484",
  },
  {
    label: "Linkedin",
    icon: <Linkedin />,
    link: "https://www.facebook.com/profile.php?id=100075055895484",
  },
  {
    label: "Twitter",
    icon: <Twitter />,
    link: "https://www.facebook.com/profile.php?id=100075055895484",
  },
];
