import React from "react";
import { Link } from "react-router-dom";
import milanDaiPic from "./MianDai.png";
import { UilSearch } from "@iconscout/react-unicons";
import { Button } from "../../Components";

export const Landing = () => {
  return (
    <div className="home__landing">
      <div className="home__landingTexts">
        <h1>Linking World With New Horizone</h1>
        <h4>By using my collections of Article relating with different aspects</h4>
        <Link className="home__searchBox" to="/search">
          <h4>Search...</h4>
          <UilSearch />
        </Link>
        <div>
          <Link to="/Article">
            <Button>Article</Button>
          </Link>
          <Link to="/Gazal">
            <Button>Gazal</Button>
          </Link>
        </div>
      </div>

      <img className="home__image" src={milanDaiPic} alt="milan-wagle-author" />
    </div>
  );
};
