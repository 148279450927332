import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { navList } from "../Header";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__details">
        <div className="footer__contact">
          <h3>GURU</h3>
          <div>
            <i className="uil uil-phone"></i> +977 9851176779
          </div>
          <div>
            <i className="uil uil-envelope"></i> er.milanwagle@gmail.com
          </div>
          <div></div>
        </div>
        <div className="footer__nav">
          <h4>Quick Navigation</h4>
          {navList.map((list) => {
            return (
              <Link to={list.link} key={list.label} className="footer__navLink">
                {list.icon} <p>{list.label}</p>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="footer__creadits">
        © All rights reserved. Created By{" "}
        <a rel="noopener noreferrer" href="https://www.facebook.com/darshu48" target="_blank">
          Darshan Dhakal
        </a>
      </div>
    </div>
  );
};
