import algoliasearch from "algoliasearch";
import { setDoc, doc } from "firebase/firestore";
import { db } from ".";

export const editData = async ({
  type,
  title,
  data,
  time,
  id,
  caption,
  category,
  keyWords,
  imgSrc,
}) => {
  const client = algoliasearch("5WR8VNEFVM", "2ed4c9e6cbba7481c225d4a791f3be8f");
  const index = client.initIndex("Blogs");
  index.saveObject({
    type: type,
    time: new Date(),
    title: title,
    objectID: id,
    caption: caption,
    keywords: keyWords,
    category: category,
    imgSrc: imgSrc,
  });
  await setDoc(doc(db, type, id), {
    time: time,
    title: title,
    content: data,
    imgSrc: imgSrc,
    caption: caption,
    category: category,
    keywords: keyWords,
  });
};
